import axios from './axiosInstance'

export const getUsers = async () => {
  try {
    const res = await axios.get('/api/testerportal/users')
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const createUser = async (data) => {
  try {
    const res = await axios.post('/api/testerportal/users', data)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const updateUser = async (data) => {
  try {
    const res = await axios.post(`/api/testerportal/users/${data.userId}/update`, data)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const deleteUser = async (userId) => {
  try {
    const res = await axios.post(`/api/testerportal/users/${userId}/delete`)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const getSourceLines = async (userLevel) => {
  try {
    const res = await axios.get(`/api/testerportal/source-lines${userLevel ? `?userLevel=${userLevel}` : ''}`)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const batchWriteSourceLines = async (data) => {
  try {
    const res = await axios.post('/api/testerportal/source-lines/batch-write', data)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const batchDeleteSourceLines = async (data) => {
  try {
    const res = await axios.post('/api/testerportal/source-lines/batch-delete', data)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const getPlayers = async (sourceLineId) => {
  try {
    const res = await axios.get(`/api/testerportal/players${sourceLineId ? `?sourceLineId=${sourceLineId}` : ''}`)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const updatePlayer = async (data) => {
  try {
    const res = await axios.post(`/api/testerportal/players/${data.playerId}/source-lines/${data.sourceLineId}`, data)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const createPlayer = async (data) => {
  try {
    const res = await axios.post('/api/testerportal/players', data)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const batchWritePlayers = async (data) => {
  try {
    const res = await axios.post('/api/testerportal/players/batch-write', data)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const getCurrencies = async (data) => {
  try {
    const res = await axios.post('/api/testerportal/currencies', data)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const updateCurrency = async (data) => {
  try {
    const res = await axios.post(`/api/testerportal/currencies/${data.currency}/update`, data)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const batchWriteCurrencies = async (data) => {
  try {
    const res = await axios.post('/api/testerportal/currencies/batch-write', data)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const getSources = async () => {
  try {
    const res = await axios.get('/api/testerportal/sources')
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const getAgentsBySource = async (source) => {
  try {
    const res = await axios.get(`/api/testerportal/agents?source=${source}`)
    return res.data
  } catch (error) {
    console.error(error)
    const { data: errData } = error.response || {}
    return errData
  }
}

export const getPlatformsBySourceAgent = async (data) => {
  const { source, agentId } = data
  const res = await axios.get(`/api/testerportal/platforms?source=${source}&agentId=${agentId}`)
  return res.data
}

export const getBetLimitsBySourceAgent = async (data) => {
  const { source, agentId } = data
  const res = await axios.get(`/api/testerportal/betlimits?source=${source}&agentId=${agentId}`)
  return res.data
}
