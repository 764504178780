import axios from 'axios'

const axiosJWT = axios.create()
axiosJWT.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  if (token) config.headers.Authorization = `Bearer ${token}`
  return config
})

export default axiosJWT
